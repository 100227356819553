import * as React from "react"
import { Datagrid, EmailField, Filter, List, SearchInput, TextField, DateField } from "react-admin"

import QuickFilter from "components/QuickFilter"

const UserFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <QuickFilter source="isStudent" label="Student" defaultValue={true} />
    <QuickFilter source="isParent" label="Parent" defaultValue={true} />
    <QuickFilter source="isAdmin" label="Admin" defaultValue={true} />
  </Filter>
)

const UserDatagrid = (props) => (
  <Datagrid rowClick="show" isRowSelectable={() => false} {...props}>
    <TextField source="firstName" />
    <TextField source="lastName" />
    <EmailField source="email" />
    <DateField source="createdAt" label="Registered" />
  </Datagrid>
)

export default (props) => (
  <List {...props} filters={<UserFilter />} perPage={25}>
    <UserDatagrid />
  </List>
)
