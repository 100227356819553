import * as React from "react"
import { makeStyles, Chip } from "@material-ui/core"

const useQuickFilterStyles = makeStyles((theme) => ({
  chip: {
    marginBottom: theme.spacing(1),
  },
}))

const QuickFilter = ({ label }) => {
  const classes = useQuickFilterStyles()
  return <Chip className={classes.chip} label={label} />
}

export default QuickFilter
