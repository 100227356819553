import buildGraphQLProvider from "ra-data-graphql-simple"
import { ApolloClient } from "apollo-client"
import { createHttpLink } from "apollo-link-http"
import { setContext } from "apollo-link-context"
import { InMemoryCache } from "apollo-cache-inmemory"

export default () => {
  const backendUrl = localStorage.getItem("backendUrl")

  if (!backendUrl) {
    return Promise.reject()
  }

  const graphqlUrl = backendUrl + "/graphql"

  const httpLink = createHttpLink({
    uri: graphqlUrl,
  })

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("token")
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })

  return buildGraphQLProvider({ client })
}
