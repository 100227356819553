import { createMuiTheme } from "@material-ui/core/styles"
import deepPurple from "@material-ui/core/colors/deepPurple"

const theme = createMuiTheme({
  palette: {
    // type: "dark",
    primary: {
      main: "rgb(160, 109, 242)",
    },
    secondary: {
      main: deepPurple[500],
    },
  },
})

export default theme
