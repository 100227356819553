import * as React from "react"
import {
  ReferenceManyField,
  EmailField,
  FunctionField,
  Show,
  SimpleShowLayout,
  Datagrid,
  TextField,
} from "react-admin"
import CurrencyField from "components/CurrencyField"
import { FisCardDataGrid } from "resources/FisCard"
import ShowAside from "./ShowAside"
import StyledField from "components/StyledField"
import Typography from "@material-ui/core/Typography"

const UserTitle = ({ record }) => (record ? `${record.firstName} ${record.lastName}` : "")

export default (props) => (
  <Show {...props} title={<UserTitle />} aside={<ShowAside />}>
    <SimpleShowLayout>
      <StyledField
        styles="title"
        component={FunctionField}
        addLabel={false}
        render={(record) => record.firstName && `${record.firstName} ${record.lastName}`}
      />
      <EmailField source="email" addLabel={false} />

      <br />
      <br />
      <Typography variant="h6">FIS Cards</Typography>

      <ReferenceManyField reference="FisCard" target="owner" addLabel={false}>
        <FisCardDataGrid />
      </ReferenceManyField>

      <br />
      <br />
      <Typography variant="h6">Journal Accounts</Typography>

      <ReferenceManyField reference="JournalAccount" target="userId" addLabel={false}>
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
          <CurrencyField source="balance" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
)
