// import { ListGuesser, ShowGuesser } from "react-admin"
// import Icon from "@material-ui/icons/HelpOutline"

export default {
  name: "JournalEntry",
  // options: { label: "Journal Entries" },
  // list: ListGuesser,
  // show: ShowGuesser,
  // icon: Icon,
}
