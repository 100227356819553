// import * as React from "react"
// import {
//   Show,
//   SimpleShowLayout,
//   List,
//   Datagrid,
//   TextField,
//   ReferenceField,
//   FunctionField,
// } from "react-admin"
// import CurrencyField from "components/CurrencyField"

// import Icon from "@material-ui/icons/HelpOutline"

// const JournalAccountList = (props) => (
//   <List {...props} perPage={25}>
//     <Datagrid rowClick="show">
//       <TextField source="id" />
//       <ReferenceField source="userId" reference="User">
//         <FunctionField render={(record) => `${record.firstName} ${record.lastName}`} />
//       </ReferenceField>
//       <TextField source="name" />

//       <CurrencyField source="balance" />
//     </Datagrid>
//   </List>
// )

// const JournalAccountShow = (props) => (
//   <Show {...props}>
//     <SimpleShowLayout>
//       <TextField source="id" />
//       <ReferenceField source="userId" reference="User">
//         <FunctionField render={(record) => `${record.firstName} ${record.lastName}`} />
//       </ReferenceField>
//       <TextField source="name" />

//       <CurrencyField source="balance" />
//     </SimpleShowLayout>
//   </Show>
// )

export default {
  // list: JournalAccountList,
  // show: JournalAccountShow,
  // icon: Icon,
  name: "JournalAccount",
  // options: { label: "Journal Accounts" },
}
