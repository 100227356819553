import * as React from "react"
import Card from "@material-ui/core/Card"

export default ({ children }) => {
  return (
    <div ref={React.createRef()}>
      <Card style={{ width: 300, marginLeft: "1em", marginBottom: "1em" }}>{children}</Card>
    </div>
  )
}
