import * as React from "react"
import { Login, LoginForm } from "react-admin"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import { styled } from "@material-ui/core/styles"

const MyFormControl = styled(FormControl)({
  width: "100%",
})

export default (environments) => (props) => {
  return (
    <Login {...props}>
      <Switcher environments={environments}>
        <LoginForm />
      </Switcher>
    </Login>
  )
}

class Switcher extends React.Component {
  constructor(props) {
    super(props)
    this.state = { environment: localStorage.getItem("backendUrl") }
    this.handleEnvChange = this.handleEnvChange.bind(this)
  }

  handleEnvChange(event) {
    localStorage.setItem("backendUrl", event.target.value)
    this.setState({ environment: event.target.value })
  }

  render() {
    return (
      <div>
        <div style={{ margin: "2em 2em" }}>
          <MyFormControl variant="outlined">
            <InputLabel id="loginEnvironmentLabel">Environment</InputLabel>
            <Select
              onChange={this.handleEnvChange}
              labelId="loginEnvironmentLabel"
              id="environment"
              name="environment"
              value={this.state.environment || ""}
              label="Environment">
              {this.props.environments.map((env) => (
                <MenuItem key={env.name} value={env.url}>
                  {env.name}
                </MenuItem>
              ))}
            </Select>
          </MyFormControl>
        </div>
        {this.state.environment ? this.props.children : ""}
      </div>
    )
  }
}
