// in App.js
import * as React from "react"
import { Component } from "react"
import { Admin, Resource } from "react-admin"
import * as resources from "./resources"
import theme from "./config/theme"
import backend from "./lib/backend"

const environments = []
if (process.env.REACT_APP_BACKEND_URL) {
  environments.push({ name: "Development", url: process.env.REACT_APP_BACKEND_URL })
}

class App extends Component {
  constructor() {
    super()
    this.state = { providers: null }
  }

  componentDidMount() {
    backend({ environments }).then((providers) => this.setState({ providers }))
  }

  render() {
    if (!this.state.providers) return ""

    return (
      <div>
        <Admin theme={theme} {...this.state.providers}>
          <Resource {...resources.User} />
          <Resource {...resources.FisCard} />
          <Resource {...resources.FisTransaction} />
          <Resource {...resources.JournalAccount} />
          <Resource {...resources.JournalEntry} />
        </Admin>
      </div>
    )
  }
}

export default App
