import * as React from "react"
import get from "lodash/get"
import { FunctionField } from "react-admin"

const CurrencyField = ({ options, source, locales, ...props }) => {
  const render = (record) => {
    const currency = get(record, `${source}.currency`)
    const cents = get(record, `${source}.cents`)

    if (cents != null) {
      return (cents / 100).toLocaleString(locales || "en-CA", {
        style: "currency",
        currency: currency,
      })
    }
  }

  return <FunctionField {...props} render={render} />
}

CurrencyField.defaultProps = { addLabel: true, textAlign: "right" }

export default CurrencyField
