import * as React from "react"

export default function FixedList({ component, ...props }) {
  const Component = component

  if (props.loaded && !!props.data) {
    return <Component {...props} />
  } else {
    return ""
  }
}
