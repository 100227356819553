import * as React from "react"
import {
  BooleanField,
  SimpleList,
  SimpleShowLayout,
  ReferenceManyField,
  TextField,
} from "react-admin"
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"

import FixedList from "components/FixedList"
import Aside from "components/Aside"

export default (props) => (
  <div>
    <Aside>
      <SimpleShowLayout {...props}>
        <BooleanField source="parentLocked" />
        <BooleanField source="studentLocked" />
        <BooleanField source="agreedToTos" label="Agreed to TOS" />
        <TextField source="timeZone" />
      </SimpleShowLayout>
    </Aside>

    <Aside>
      <SimpleShowLayout {...props}>
        <ReferenceManyField reference="User" target="belongsToParent" addLabel={false}>
          <FixedList
            component={SimpleList}
            primaryText={(record) => "Student"}
            secondaryText={(record) => record && `${record.firstName} ${record.lastName}`}
            linkType="show"
            rightIcon={() => <AccountCircleIcon />}
          />
        </ReferenceManyField>

        <ReferenceManyField reference="User" target="hasStudent" addLabel={false}>
          <SimpleList
            primaryText={(record) => "Parent"}
            secondaryText={(record) => record && `${record.firstName} ${record.lastName}`}
            linkType="show"
            rightIcon={() => <SupervisedUserCircleIcon />}
          />
        </ReferenceManyField>
      </SimpleShowLayout>
    </Aside>
  </div>
)
