import * as React from "react"
import get from "lodash/get"
import { FunctionField } from "react-admin"

function timeDifference(current, previous) {
  var msPerMinute = 60 * 1000
  var msPerHour = msPerMinute * 60
  var msPerDay = msPerHour * 24
  var msPerMonth = msPerDay * 30
  var msPerYear = msPerDay * 365

  var elapsed = current - previous

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " seconds ago"
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " minutes ago"
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hours ago"
  } else if (elapsed < msPerMonth) {
    return "about " + Math.round(elapsed / msPerDay) + " days ago"
  } else if (elapsed < msPerYear) {
    return "about " + Math.round(elapsed / msPerMonth) + " months ago"
  } else {
    return "about " + Math.round(elapsed / msPerYear) + " years ago"
  }
}

const RelativeDateField = ({ options, source, locales, ...props }) => {
  const render = (record) => {
    const value = get(record, source)
    if (value !== null) {
      return timeDifference(Date.now(), Date.parse(value))
    }
  }

  return <FunctionField {...props} render={render} />
}

RelativeDateField.defaultProps = { addLabel: true }

export default RelativeDateField
