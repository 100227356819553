export default () => {
  return {
    login: ({ username, password }) => {
      const loginUrl = localStorage.getItem("backendUrl") + "/users/sign_in.json"

      return fetch(loginUrl, {
        method: "POST",
        body: JSON.stringify({ user: { email: username, password: password } }),
        headers: { "Content-Type": "application/json" },
      }).then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }

        localStorage.setItem("token", response.headers.get("Authorization").split(" ").pop())
        return new Promise(() => window.location.reload())
      })
    },
    logout: (params) => {
      localStorage.removeItem("token")
      return Promise.resolve()
    },
    checkAuth: (params) => {
      return localStorage.getItem("token") ? Promise.resolve() : Promise.reject()
    },
    getPermissions: (params) => Promise.resolve(),
    checkError: ({ networkError }) =>
      networkError.statusCode === 401 || networkError.statusCode === 403
        ? Promise.reject()
        : Promise.resolve(),
  }
}
