import Show from "./Show"
import List from "./List"
import Icon from "@material-ui/icons/People"

export default {
  name: "User",
  list: List,
  show: Show,
  icon: Icon,
}
