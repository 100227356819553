import * as React from "react"
import { List, Datagrid, TextField, ReferenceField, FunctionField, ShowGuesser } from "react-admin"
import Icon from "@material-ui/icons/CreditCard"
import CurrencyField from "components/CurrencyField"
import RelativeDateField from "components/RelativeDateField"
import FixedList from "components/FixedList"

const STATUS = {
  0: "DORMANT",
  1: "READY",
  2: "ACTIVE",
  3: "CLOSED",
  4: "LOST",
  5: "REPLACED",
  6: "SUSPENDED",
  7: "EXPIRED",
  8: "SACTIVE",
  9: "REVOKED",
  11: "CCLOSED",
  12: "MBCLOSED",
  14: "FRAUD",
  15: "PFRAUD",
  16: "LASTSTMT",
  17: "CHARGEOFF",
  18: "DECEASED",
  19: "WARNING",
  20: "MUCLOSED",
  21: "VOID",
  22: "NONRENEWAL",
  23: "DESTROYED",
}

const getStatus = (record) => {
  return record && STATUS[record.status]
}

const onRowClick = (id, basePath, record) => {
  return {
    pathname: "/FisTransaction",
    search: `filter=${JSON.stringify({ processorCardId: id })}`,
  }
}

export const FisCardDataGrid = (props) => (
  <FixedList component={Datagrid} rowClick={onRowClick} isRowSelectable={() => false} {...props}>
    <TextField source="last4" />

    <ReferenceField label="Owner" source="owner.id" reference="User" link="show">
      <FunctionField render={(record) => `${record.firstName} ${record.lastName}`} />
    </ReferenceField>

    <CurrencyField source="balance" />
    <RelativeDateField source="updatedAt" label="Updated" textAlign="right" />
    <FunctionField textAlign="right" render={getStatus} showLabel label="Status" />
    <TextField source="id" textAlign="right" />
  </FixedList>
)

const FisCardList = (props) => (
  <List {...props} perPage={25}>
    <FisCardDataGrid />
  </List>
)

export default {
  name: "FisCard",
  list: FisCardList,
  show: ShowGuesser,
  options: { label: "FIS Cards" },
  icon: Icon,
}
