import * as React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { TextField } from "react-admin"

const useStyles = makeStyles({
  title: { fontSize: "1.5em" },
})

const StyledField = ({ styles, component, ...props }) => {
  const classes = useStyles()
  const Component = component || TextField

  return <Component className={classes[styles]} {...props} />
}

StyledField.defaultProps = {
  addLabel: true,
}

export default StyledField
