import * as React from "react"
import Icon from "@material-ui/icons/Receipt"
import Card from "@material-ui/core/Card"
import {
  Datagrid,
  Filter,
  List,
  SearchInput,
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  DateInput,
  ReferenceManyField,
  ReferenceField,
  FunctionField,
  SelectInput,
} from "react-admin"
import CurrencyField from "components/CurrencyField"

const TransactionFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <SearchInput source="processorCardId" />

    <SelectInput
      label="Code"
      source="transactionCode"
      choices={[
        { id: "Adjustment", name: "Adjustment" },
        { id: "Decline", name: "Decline" },
        { id: "Fees", name: "Fees" },
        { id: "Non-Mon Update", name: "Non-Mon Update" },
        { id: "NonMon", name: "NonMon" },
        { id: "Outstanding Purchase", name: "Outstanding Purchase" },
        { id: "Purchase", name: "Purchase" },
        { id: "Reversal", name: "Reversal" },
        { id: "ValueLoad", name: "ValueLoad" },
      ]}
    />

    <DateInput label="Settled After" source="settlementDateGte" />
    <DateInput label="Settled Before" source="settlementDateLt" />

    <DateInput label="Transaction After" source="transactionDateGte" />
    <DateInput label="Transaction Before" source="transactionDateLt" />

    <DateInput label="Created After" source="createdAtGte" />
    <DateInput label="Created Before" source="createdAtLt" />
  </Filter>
)

const defaultListProps = {
  perPage: 25,
  filters: <TransactionFilter />,
  sort: { field: "createdAt", order: "DESC" },
}

const TransactionList = (props) => (
  <List {...props} {...defaultListProps} title="Transactions">
    <Datagrid rowClick="show" isRowSelectable={() => false}>
      <DateField source="createdAt" showTime />
      <TextField source="merchantName" />

      <ReferenceField label="User" source="user.id" reference="User" link="show">
        <FunctionField render={(record) => `${record.firstName} ${record.lastName}`} />
      </ReferenceField>

      <TextField source="transactionCode" label="Code" />
      <TextField source="transactionDescription" label="Desc" />
      <CurrencyField label="Amount" source="transactionAmount" />
    </Datagrid>
  </List>
)

const TransactionAside = (props) => (
  <div style={{ width: 300, marginLeft: "1em" }}>
    <Card>
      <SimpleShowLayout {...props}>
        <CurrencyField
          label="Amount"
          source="transactionAmount"
          currencySource="cardCurrencyCode"
        />

        <CurrencyField
          label="Local Amount"
          source="localCurrencyAmount"
          currencySource="localCurrencyCode"
        />
      </SimpleShowLayout>
    </Card>
    <br />

    <Card>
      <SimpleShowLayout {...props}>
        <ReferenceField label="User" source="user.id" reference="User" link="show">
          <FunctionField render={(record) => `${record.firstName} ${record.lastName}`} />
        </ReferenceField>

        <ReferenceField label="Card" source="card.id" reference="FisCard" link={false}>
          <TextField source="last4" />
        </ReferenceField>
      </SimpleShowLayout>
    </Card>
    <br />

    <Card>
      <SimpleShowLayout {...props}>
        <DateField
          label="Transaction"
          source="transactionDate"
          options={{
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
            timeZone: "UTC",
            timeZoneName: "short",
          }}
        />

        <DateField
          label="Settled"
          source="settlementDate"
          showTime
          options={{
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "2-digit",
            timeZoneName: "short",
          }}
        />

        <DateField
          label="Imported"
          source="createdAt"
          showTime
          options={{
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "2-digit",
            timeZoneName: "short",
          }}
        />
      </SimpleShowLayout>
    </Card>
  </div>
)

const TransactionShow = (props) => (
  <Show {...props} aside={<TransactionAside />}>
    <SimpleShowLayout>
      <TextField source="merchantName" addLabel={false} />
      <TextField source="transactionCode" addLabel={false} />

      <br />

      <TextField source="reference" />

      <br />

      <TextField source="transactionDescription" />

      <br />

      <ReferenceManyField
        label="Potentially Related Transactions"
        reference="FisTransaction"
        target="relatedTo">
        <Datagrid rowClick="show">
          <DateField source="createdAt" showTime />
          <TextField source="merchantName" />
          <TextField source="transactionCode" label="Code" />
          <CurrencyField
            label="Amount"
            source="transactionAmount"
            currencySource="cardCurrencyCode"
          />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
)

export default {
  name: "FisTransaction",
  list: TransactionList,
  show: TransactionShow,
  options: { label: "FIS Transactions" },
  icon: Icon,
}
