import authProvider from "./authProvider"
import graphqlProvider from "./graphqlProvider"
import Login from "./Login"

const fakeProvider = () => Promise.reject({ networkError: { statusCode: 401 } })

const defaultEnvironments = [
  { name: "Production", url: "https://sidekick-production.herokuapp.com" },
  { name: "Staging", url: "https://sidekick-staging.herokuapp.com" },
]

export default ({ environments = [] }) => {
  return graphqlProvider()
    .catch((error) => fakeProvider)
    .then((dataProvider) => ({
      dataProvider,
      authProvider: authProvider(),
      loginPage: Login(Array.prototype.concat(defaultEnvironments, environments)),
    }))
}
